
import { defineComponent, ref, onMounted, reactive, computed } from "vue"
import { useRoute } from "vue-router"
import { ElForm } from "element-plus"
import ApiService from "@/core/services/ApiService"
import router from "@/router"

import { InternalRuleItem } from "async-validator"

export default defineComponent({
  name: "sign-up",
  setup() {
    const route = useRoute()

    type FormInstance = InstanceType<typeof ElForm>
    const formSize = ref("")
    const userName = ref("")
    const rpFormRef = ref<FormInstance>()

    const rpForm = reactive({
      password1: "",
      password2: "",
    })

    const resetPasswordDto = computed(() => {
      return {
        password: rpForm.password1 == rpForm.password2 ? rpForm.password2 : "",
        passwordResetKey: route.params.id,
      }
    })

    const getUser = async () => {
      userName.value = route.query.user as string
    }

    onMounted(() => {
      validateResetKey()
      getUser()
    })

    const validateResetKey = async () => {
      const config = {}
      await ApiService.post(
        "auth/verify-reset-password-key?resetPasswordKey=" + route.params.id,
        config
      )
    }

    const passwordPolicyFormat = new RegExp(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d]{8,}$/)

    const validatePass = (
      rule: InternalRuleItem,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      callback: (error?: string | Error | undefined) => void
    ) => {
      if (value === "") {
        callback(new Error("Lütfen Şifre Giriniz!"))
      } else if (!passwordPolicyFormat.test(value)) {
        callback(new Error("En az 8 karakter olmalı, 1 büyük harf ve 1 rakam içermelidir!"))
      } else {
        if (rpForm.password2 !== "") {
          if (!rpFormRef.value) return
          rpFormRef.value.validateField("checkPass", () => null)
        }
        callback()
      }
    }
    const validatePass2 = (
      rule: InternalRuleItem,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      callback: (error?: string | Error | undefined) => void
    ) => {
      if (value === "") {
        callback(new Error("Lütfen Şifreyi Tekrar Giriniz!"))
      } else if (!passwordPolicyFormat.test(value)) {
        callback(new Error("En az 8 karakter olmalı, 1 büyük harf ve 1 rakam içermelidir!"))
      } else if (value !== rpForm.password1) {
        callback(new Error("Girilen şifreler Birbiriyle Eşleşmiyor!"))
      } else {
        callback()
      }
    }

    function submitForm(formEl: FormInstance | undefined) {
      if (!formEl) return
      formEl.validate(async valid => {
        if (valid) {
          const config = resetPasswordDto.value
          await ApiService.post("auth/reset-password", config)
          router.push({ name: "sign-in" })
        } else {
          return false
        }
      })
    }

    const rules = reactive({
      password1: [{ validator: validatePass, trigger: "blur" }],
      password2: [{ validator: validatePass2, trigger: "blur" }],
    })

    return {
      formSize,
      rpFormRef,
      resetPasswordDto,
      rules,
      submitForm,
      rpForm,
      userName,
    }
  },
})
